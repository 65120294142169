import React from 'react'
import Layout from '../components/Layout/Layout'
import { PageProps } from "gatsby"
import SEO from '../components/seo'

const login: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
    <SEO title="Login" />
      Login
    </Layout>
  )
}

export default login



